.closeButton {
  position: absolute;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 80px;
  border: none;
  outline: none;
  background-color: #ebebeb;
  transform: translateX(100%);

  svg {
    color: var(--gray-dark);
  }
}

.modal {
  width: fit-content !important;
  margin-right: auto;
}