@import "../../../styles/fonts.scss";
@import "../../../styles/_mixins.scss";

.container {
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    justify-content: center;
    gap: 37px;
    padding: 48px 20px;
  }
}

.content {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 767px) {
    padding: 32px 24px;
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    max-width: 600px;
    width: calc(50% - 38.5px);
  }
}

.image {
  object-fit: contain;

  @media screen and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    max-width: 600px;
    width: calc(50% - 38.5px);
    border: 1px solid var(--primary);
  }
}

.text {
  display: inline-flex;
  flex-wrap: wrap;
  gap: 0 5px;
  font-size: 1rem;
  font-weight: 700;
  line-height: 1.5rem;
  color: var(--black);

  a {
    display: inline-flex;
    color: var(--primary);
    transition: var(--transition);

    &:hover {
      color: var(--primary-dark);
    }
  }

  & + .form {
    margin-top: 24px;
  }
}

.form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.button {
  @media screen and (max-width: 767px) {
    font-size: 0.875rem !important;
    line-height: 1.225em !important;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.125rem !important;
    line-height: 1.4em !important;
  }
}
