.container {
  position: relative;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    width: 100%;
  }

  @media screen and (min-width: 768px) {
    flex-basis: 34.5%;
    height: 100%;
    border-right: 1px solid var(--gray-border);
  }
}

.image {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 100%;

  @media screen and (min-width: 768px) {
    max-height: 225px;
    height: 100%;
  }

  &:not(.imageService) img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &Logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--gray-light);

    img {
      max-height: 40%;
      max-width: 75%;
      margin: 0 auto;
      object-fit: contain !important;
      filter: grayscale(100%);
    }
  }
}

.title {
  font-weight: bold;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin: 50px 15px 20px;
    font-size: 1.25rem;
    line-height: 1.4375rem;
  }

  @media screen and (min-width: 768px) {
    text-align: center;
    margin: 35px 35px 20px;
    font-size: 1rem;
    line-height: 1.5rem;
  }
}

.info {
  display: flex;
  flex-direction: column;
  flex: 1;
  background-color: var(--gray-light);

  @media screen and (min-width: 768px) and (max-width: 839px) {
    padding: 25px 15px;
  }

  @media screen and (min-width: 840px) {
    padding: 25px;
  }

  &TestDrive {
    .items {
      @media screen and (min-width: 768px) {
        gap: 30px 5px;
      }
    }

    .item {
      margin-bottom: 5px;

      @media screen and (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }

      &Title {
        margin-bottom: 0;
      }

      &Value {
        margin-left: 20px;
      }
    }
  }
}

.items {
  @media screen and (min-width: 279px) and (max-width: 767px) {
    display: flex;
    flex-wrap: wrap;
  }
  @media screen and (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
}

.item {
  display: flex;
  flex-direction: column;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    flex: 1 1 50%;
    padding: 30px 20px;
    background-color: var(--gray-light);
    border-bottom: 1px solid var(--gray-text);

    &:nth-child(odd) {
      border-right: 1px solid var(--gray-text);
    }
  }

  &Container {
    display: flex;
    flex: 1;

    @media screen and (min-width: 768px) {
      width: 200%;
      flex-direction: column;
    }
  }

  &Title {
    display: flex;
    margin-bottom: 5px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 1.0625rem;
    color: var(--gray-dark);
  }

  &Value {
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.5rem;
    color: var(--black);
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  &Long {
    @media screen and (min-width: 768px) {
      margin-top: 20px;
      // width: 200%;
    }
  }
}

.itemSingle {
  @media screen and (min-width: 279px) and (max-width: 767px) {
    flex: 1 1 100%;
    padding: 30px 20px;
    background-color: var(--gray-light);
    border-bottom: 1px solid var(--gray-text);
  }

  & + .item {
    border-right: 1px solid var(--gray-text);
  }
}

.goBack {
  display: flex;
  align-items: center;
  justify-content: center;
  width: fit-content;
  margin-top: auto;
  height: 40px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.8125rem;
  background-color: transparent;
  color: var(--black);
  border: none;
  outline: none;
  transition: var(--transition);

  svg {
    path {
      fill: var(--black);
    }
  }

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-top: 35px;
    margin-left: 15px;

    & + .title {
      margin-top: 15px;
    }
  }

  @media screen and (min-width: 768px) {
    svg {
      margin-right: 5px;

      path {
        transition: var(--transition);
      }
    }
  }
}
