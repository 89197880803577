.questions {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: var(--content-width);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-bottom: 60px;
  }

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    padding: 0 15px;
  }

  @media screen and (min-width: 1280px) {
    margin: 0 auto;
  }

  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    padding: 0 105px;
  }

  &Title {
    font-weight: bold;
    color: var(--black);

    @media screen and (min-width: 279px) and (max-width: 767px) {
      margin: 40px 0;
      font-size: 1.5rem;
      line-height: 1.75rem;
    }

    @media screen and (min-width: 768px) {
      margin: 60px 0;
      font-size: 2rem;
      line-height: 1.875rem;
    }
  }
}
