.container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 60px;
  z-index: 1;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    padding: 0 5px;
  }

  @media screen and (min-width: 768px) {
    padding: 0 15px 0 25px;
  }

  &.forSRP {
    background-color: var(--white);
  }
}

.pagination {
  display: flex;
  align-items: center;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    width: 100%;
  }
}

.page,
.break {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--black);
  border-radius: 50%;
  background-color: transparent;
  transition: var(--transition);
  cursor: pointer;

  & > a {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
  }
}

.break {
  &:hover {
    color: var(--primary);
  }
}

.page {
  &:hover:not(.pageActive) {
    color: var(--primary);
  }

  &Active {
    color: var(--white);
    background-color: var(--primary);
  }
}

.arrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;

  & > a {
    display: flex;
  }

  svg {
    path {
      stroke: var(--black);
      transition: var(--transition);
    }
  }

  &:not(.arrowDisabled):hover {
    svg {
      path {
        stroke: var(--primary);
      }
    }
  }

  &Prev {
    transform: rotate(90deg);

    @media screen and (min-width: 279px) and (max-width: 767px) {
      margin-right: auto;
    }

    @media screen and (min-width: 768px) {
      margin-right: 15px;
    }
  }

  &Next {
    transform: rotate(270deg);

    @media screen and (min-width: 279px) and (max-width: 767px) {
      margin-left: auto;
    }

    @media screen and (min-width: 768px) {
      margin-left: 15px;
    }
  }

  &Disabled {
    svg {
      path {
        stroke: var(--gray-secondary);
      }
    }
  }
}

.description {
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--gray-dark);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    display: none;
  }
}
