.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: var(--white);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    min-height: 100vh;
    overflow-y: auto;
  }
}

.header {
  display: flex;
  flex-shrink: 0;
  background-color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 499px) {
    height: 105px;
  }

  @media screen and (min-width: 500px) {
    height: 90px;
  }

  &Title {
    font-weight: bold;
    color: var(--white);

    @media screen and (min-width: 279px) and (max-width: 499px) {
      margin-top: 60px;
      margin-left: 15px;
      font-size: 1.25rem;
      line-height: 1.4375rem;
    }

    @media screen and (min-width: 500px) {
      margin-top: 30px;
      margin-left: 40px;
      font-size: 1.625rem;
      line-height: 1.5625rem;
    }
  }
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  background-color: var(--white);

  @media screen and (min-width: 279px) and (max-width: 499px) {
    gap: 15px;
    padding: 15px;
  }

  @media screen and (min-width: 500px) {
    gap: 20px;
    padding: 40px;
  }
}

.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  background-color: var(--gray-light-menu);
  transition: var(--transition);
  border: 1px solid transparent;
  cursor: pointer;

  @media screen and (min-width: 279px) and (max-width: 499px) {
    padding: 35px 15px;
    height: 210px;
  }

  @media screen and (min-width: 500px) {
    height: 170px;
    padding: 35px 30px 25px;
  }

  &.monochrome {
    svg {
      rect {
        &:nth-child(1) {
          fill: var(--black);
        }
      }
    }
  }

  &.contrast {
    svg {
      fill: var(--black);
    }
  }

  &:hover:not(.cardActive) {
    border-color: var(--black);
    background-color: var(--gray-light);
  }

  &Active {
    background-color: var(--black-light);

    .text {
      color: var(--white);
    }

    &:hover {
      background-color: var(--black);
    }

    &.biggerText {
      svg {
        rect {
          fill: var(--white);
        }
      }
    }

    &.monochrome {
      svg {
        rect {
          &:nth-child(1) {
            fill: var(--white);
          }
          &:nth-child(2) {
            fill: #d6d6d6;
          }

          &:nth-child(3) {
            fill: #858585;
          }

          &:nth-child(4) {
            fill: #adadad;
          }
        }
      }
    }

    &.contrast,
    &.bigCursor {
      svg {
        path {
          fill: var(--white);
        }
      }
    }
  }

  &.biggerText,
  &.monochrome {
    svg {
      rect {
        transition: var(--transition);
      }
    }
  }

  &.contrast,
  &.bigCursor {
    svg {
      path {
        transition: var(--transition);
      }
    }
  }
}

.text {
  text-align: center;
}
