.view {
  display: flex;
  align-items: center;
  margin-right: 15px;

  &Button {
    border: none;
    outline: none;
    background-color: transparent;

    &:first-child {
      margin-right: 5px;
    }

    svg {
      color: var(--gray-secondary);
    }

    svg {
      rect {
        transition: var(--transition);
      }
    }

    &.active {
      svg {
        rect {
          stroke: var(--primary);
        }
      }
    }

    &:hover {
      svg {
        rect {
          stroke: var(--primary-dark);
        }
      }
    }
  }
}
