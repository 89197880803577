@import "../../../styles/fonts.scss";
@import "../../../styles/_mixins.scss";

.search {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background-color: var(--gray-soft);

  &.light {
    background-color: var(--gray-soft);
  }

  &.primary {
    background-color: rgb(from var(--primary) r g b / 20%);
  }

  @media screen and (max-width: 1279px) {
    padding: 32px 16px;
  }

  @media screen and (min-width: 1280px) {
    padding: 32px 72px;
  }

  &Title {
    color: var(--primary);
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 1.07em;

    @media screen and (max-width: 1279px) {
      margin-bottom: 12px;
    }

    @media screen and (min-width: 1280px) {
      margin-bottom: 16px;
    }
  }

  &Panel {
    display: grid;
    width: 100%;

    @media screen and (min-width: 1280px) {
      max-width: 1670px;
    }

    @media screen and (min-width: 1100px) {
      grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
    }

    @media screen and (max-width: 1099px) {
      max-width: 568px;
    }

    &Row {
      @media screen and (max-width: 1099px) {
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-auto-columns: 1fr;

        & > * {
          &:nth-child(n + 3) {
            grid-column: span 2;
          }
        }
      }
    }
  }

  &Button {
    width: inherit !important;
    min-height: 60px;
    height: 60px !important;
    white-space: nowrap;

    @media screen and (max-width: 1099px) {
      border-radius: 0 0 6px 6px;
    }

    @media screen and (min-width: 1100px) {
      border-radius: 0 6px 6px 0;
    }
  }
}
