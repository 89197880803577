.label {
  position: relative;
  display: flex;
  align-items: center;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.0625rem;
  cursor: pointer;
  color: var(--gray-dark-border);
  transition: var(--transition);

  &:hover {
    color: var(--black);

    .checkboxIcon {
      border-color: var(--black);
    }
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  &.checked {
    color: var(--black);

    .checkboxIcon {
      background-color: var(--black);
      border-color: var(--black);

      svg {
        opacity: 1;
      }
    }
  }
}

.checkboxIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;
  width: 20px;
  height: 20px;
  margin-right: 8px;
  // background-color: var(--white);
  border: 1.5px solid var(--gray-dark);
  border-radius: 3px;
  transition: var(--transition);

  svg {
    color: var(--white);
    margin-left: 1px;
    opacity: 0;
    transition: var(--transition);
  }
}

.option {
  display: flex;
  margin-right: 5px;
  transition: var(--transition);

  &Container {
    display: flex;
    align-items: center;
  }
}
