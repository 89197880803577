.info {
  @media screen and (min-width: 279px) and (max-width: 999px) {
    display: flex;
    flex-direction: column;
    background-color: var(--gray-light-menu);
    padding: 16px;
    border-radius: 6px;
    gap: 10px;
  }

  @media screen and (min-width: 1000px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 12px;
  }
}

.list {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 999px) {
    gap: 10px;
  }

  @media screen and (min-width: 1000px) {
    gap: 14px;
    padding: 16px;
    background-color: var(--gray-light-menu);
    border-radius: 6px;
  }
}

.item {
  display: flex;
  align-items: center;
  height: 26px;

  @media screen and (max-width: 767px) {
    gap: 20px;
  }

  &Value {
    display: block;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: right;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;

    @media screen and (max-width: 767px) {
      font-size: 0.875rem;
      line-height: 0.9375rem;
      color: var(--black-dark);
    }

    @media screen and (min-width: 768px) {
      font-size: 1rem;
      line-height: 1.25rem;
      color: #181818;
    }
  }

  &Title {
    display: inline-flex;
    margin-right: 5px;
    font-weight: 500;

    @media screen and (max-width: 767px) {
      font-size: 0.875rem;
      line-height: 1.0625rem;
      color: var(--gray-description);
    }

    @media screen and (min-width: 768px) {
      font-size: 1rem;
      line-height: 1.25rem;
      color: #181818;
    }
  }

  &Color {
    display: flex;
    align-items: center;
    gap: 4px;
  }

  &Container {
    display: flex;
    align-items: baseline;
    flex: 1;
    flex-shrink: 0;
    margin-right: 10px;
  }
}

.text {
  display: flex;
  font-size: 1rem;
  line-height: 1.25rem;
  color: var(--black);

  &.bold {
    font-weight: 500;
  }
}

.colorField {
  display: flex;
  flex: 1;
  margin: 0 5px;
  border-bottom: 1px dashed var(--gray-text);
  flex-shrink: 0;
  width: 11px;
  height: 11px;
  border: 1px solid #a4a4a4;

  &.circle {
    border-radius: 50%;
  }
}

.iconContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  width: 25px;
  height: 25px;
  margin-right: 10px;

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.dashed {
  display: flex;
  flex: 1;
  margin: 0 5px;
  border-bottom: 1px dashed var(--gray-text);

  @media screen and (max-width: 767px) {
    display: none;
  }
}
