.container {
  display: flex;
  align-items: center;
  height: 20px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 2px 8px;
  border-radius: 4px;
  background-color: rgb(from var(--primary) r g b / 13%);
  color: var(--primary);
}
