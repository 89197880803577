.checkbox {
  font-weight: 400;

  @media screen and (max-width: 767px) {
    font-size: 0.75rem;
    line-height: 0.8125rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 0.875rem;
    line-height: 0.8125rem;
  }
}
