.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    padding: 30px 20px;
  }

  @media screen and (min-width: 768px) {
    padding: 90px 70px 40px;
  }

  &.finance {
    align-items: center;

    @media screen and (min-width: 279px) and (max-width: 767px) {
      padding-top: 20vh;
      text-align: center;
    }

    svg {
      margin-bottom: 25px;
      color: var(--black);
    }
  }
}

.title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 1.625rem;
  line-height: 1.875rem;
  color: var(--black);
  text-align: center;
}

.text {
  margin: 0 auto auto;
  font-size: 1rem;
  line-height: 1.4375rem;
  color: var(--dark);
  // white-space: pre;
  text-align: center;
  max-width: 420px;
}

.done {
  @media screen and (max-width: 767px) {
    font-size: 0.875rem !important;
    line-height: 1.225em !important;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.125rem !important;
    line-height: 1.4em !important;
    max-width: 270px;
    margin: 0 auto;
  }
}
