.container {
  border-bottom: 1px solid var(--gray-text);

  &:last-child {
    border-bottom: none;
    box-shadow: 0px 0px 0px 1px var(--gray-text);
  }
}

.header {
  cursor: pointer;

  svg {
    path {
      transition: var(--transition);
    }
  }

  .minus {
    path {
      fill: var(--gray-secondary);
    }
  }

  .plus {
    path {
      stroke: var(--gray-secondary);
    }
  }

  &:hover {
    .minus {
      path {
        fill: var(--black);
      }
    }

    .plus {
      path {
        stroke: var(--black);
      }
    }
  }

  &Container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px 25px;
  }
}

.name {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--black);
  text-transform: capitalize;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 0 25px 30px;
}

.checkbox {
  margin-bottom: 20px;

  &:last-child {
    margin-bottom: 0;
  }
}
