.container {
  width: 100%;
  max-width: var(--content-width);

  @media screen and (min-width: 768px) {
    margin: 70px auto 90px;
  }

  @media screen and (min-width: 1280px) and (max-width: 1919px) {
    padding: 0 105px;
  }
}
