.container {
  display: flex;
  flex-direction: column;
}

.items {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;

  @media screen and (min-width: 320px) and (max-width: 374px) {
    gap: 12px 2.5px;
  }

  @media screen and (min-width: 375px) and (max-width: 767px) {
    gap: 12px 21px;
  }

  @media screen and (min-width: 768px) {
    gap: 32px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 70px;

  &Icon {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 70px;
    height: 70px;
    margin-bottom: 8px;
    background: var(--gray-light-menu);
    border-radius: 8px;

    svg {
      color: var(--dark);
    }
  }

  &Title {
    font-size: 0.625rem;
    line-height: 0.71875rem;
    color: var(--gray-description);
  }
}

.button {
  margin: 24px auto 0;
}
