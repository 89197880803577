.container {
  position: relative;
  display: flex;
  width: 100%;
  padding: 24px;
  border-bottom: 1px solid var(--gray-text);
  border-right: 1px solid var(--gray-text);
  transition: var(--transition);

  @media screen and (min-width: 960px) and (max-width: 1279px) {
    &:first-child {
      border-top: 1px solid var(--gray-text);
    }
  }

  &:before,
  &:after {
    position: absolute;
    top: -1px;
    left: -1px;
    content: "";
    background-color: var(--gray-dark);
    opacity: 0;
    transition: var(--transition);
  }

  &:before {
    width: calc(100% + 2px);
    height: 1px;
  }

  &:after {
    width: 1px;
    height: calc(100% + 2px);
  }

  &:hover {
    border-color: var(--gray-dark);

    &:before,
    &:after {
      opacity: 1;
    }
  }
}

.centerSection {
  display: flex;
  flex-direction: column;
  padding-right: 16px;
  border-right: 1px solid var(--gray-text);

  flex: 1 1 50%;
}

.description {
  position: relative;
  display: flex;
  flex-direction: column;
  padding-bottom: 23px;
  padding-top: 8px;
  margin-bottom: 23px;
  border-bottom: 1px solid var(--gray-text);

  &Container {
    display: flex;
    align-items: center;
    margin-bottom: 12px;
  }
}

.likeButton {
  margin-left: auto;
}

.details {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;
  margin-bottom: 10px;
}

.compare {
  margin-right: 10px;
}

.actions {
  display: flex;
  flex-direction: column;
  flex: 1;
  margin-bottom: 10px;
}
.button {
  margin-bottom: 10px;

  &:last-child {
    margin-bottom: 0;
  }
}
.viewDetails {
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.125rem;
  color: var(--primary);
  transition: var(--transition);
  cursor: pointer;

  &:hover {
    color: var(--primary-dark);
  }
}

.info {
  display: flex;
  align-items: center;
  margin-bottom: 8px;

  &:last-of-type {
    margin-bottom: 0;
  }

  &Title,
  &Value {
    font-size: 0.875rem;
    line-height: 1.0625rem;
  }

  &Title {
    font-weight: 500;
    margin-right: auto;
    text-transform: capitalize;
    color: var(--gray-description);
  }

  &Value {
    margin-left: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #000000;
  }

  &Container {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    &.price {
      margin-bottom: 42px;
    }
  }
}

.priceInfo {
  display: flex;
  align-items: center;
  padding-bottom: 14px;

  &:last-of-type {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  &Title,
  &Value {
    font-size: 0.875rem;
    line-height: 1.0625rem;
    @media screen and (min-width: 1024px) {
      font-size: 1rem;
    }
    @media screen and (min-width: 1920px) {
      font-size: 1.125rem;
    }
  }

  &Title {
    font-weight: 500;
    margin-right: auto;
    text-transform: capitalize;
    color: var(--gray-description);
  }

  &Value {
    margin-left: 25px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
    color: #000000;
  }

  &Container {
    display: flex;
    flex-direction: column;
    margin-bottom: 42px;
    padding: 16px;
    border-radius: 6px;
    background: var(--gray-light-menu);
  }
}

.year {
  margin-left: 6px;
}

.yearMake {
  display: flex;
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--black);
}

.make {
  display: inline-flex;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 1.375rem;
  line-height: 1.25rem;
  color: var(--black);
}

.stock {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--gray-dark);
}

.callForPrice {
  font-weight: 500;
  font-size: 1rem;
  line-height: 1rem;
  margin-left: 10px;
  color: var(--primary);
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;
}

.right {
  display: flex;
  flex-direction: column;
  min-width: 180px;
  padding-left: 16px;
  padding-top: 8px;
  flex: 1 1 50%;
}
