.vehicleContainer {
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    padding: 0 15px 40px;
  }

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    padding: 0 15px 60px;
  }

  @media screen and (min-width: 1280px) {
    padding: 0 0 60px;
  }
}
