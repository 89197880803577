.content {
  display: flex;
  width: 100%;
  background-color: var(--white);

  &.isSmartphone {
    height: 100vh;
    overflow-y: auto;
  }
}
