.container {
  margin-bottom: 24px;
  cursor: pointer;
}

.content {
  padding: 12px 0;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 12px;
  border-bottom: 1px solid var(--gray-border);

  &Left {
    display: flex;
    flex-direction: column;
    margin-right: 4px;
  }

  &Title {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.25rem;
    color: var(--black-dark);
  }

  &Subtitle {
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: var(--gray-title);
  }

  svg {
    color: #181818;
    transition: var(--transition);
  }

  &.isOpen {
    svg {
      transform: rotate(180deg);
    }
  }
}
