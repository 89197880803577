.container {
  position: relative;
  display: flex;
  flex-shrink: 0;
}

.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content {
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.35);
  backdrop-filter: blur(7.5px);
  z-index: 1;

  &Container {
    display: flex;
    width: 100%;

    @media screen and (min-width: 279px) and (max-width: 767px) {
      flex-direction: column;
    }

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      padding: 25px 15px;
    }

    @media screen and (min-width: 1280px) and (max-width: 1919px) {
      padding: 25px 105px;
    }

    @media screen and (min-width: 768px) {
      align-items: center;
    }

    @media screen and (min-width: 1920px) {
      max-width: var(--content-width-big);
      width: 100%;
      padding: 25px 0;
      margin: 0 auto;
    }
  }
}

.title {
  font-weight: 500;
  font-size: 1.6rem;
  line-height: 2.25rem;

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    margin-right: 20px;
  }

  @media screen and (min-width: 1280px) {
    margin-right: 50px;
  }
}

.title,
.text {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  color: var(--white);
}

.text {
  font-size: 1rem;
  line-height: 1.3rem;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-bottom: 25px;
  }

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    margin-right: 20px;
  }

  @media screen and (min-width: 1280px) {
    margin-right: 30px;
  }
}

.button {
  padding: 0 24px;
  margin-left: auto;

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    max-width: 240px;
  }

  @media screen and (min-width: 1280px) {
    max-width: 290px;
  }
}
