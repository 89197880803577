.info {
  display: flex;

  @media screen and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    padding: 0 16px;
    gap: 12px;
    align-items: center;
    justify-content: space-between;
  }
}

.dealer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  &Name,
  &Address {
    font-size: 1rem;
    line-height: 1.375rem;
  }

  &Name {
    font-weight: 700;
    color: var(--black-dark);
    @media screen and (min-width: 768px) {
      display: none;
    }
  }

  &Address {
    color: var(--gray-description);
  }
}

.actions {
  display: flex;
  gap: 12px;

  @media screen and (max-width: 767px) {
    margin: 24px 0 0;
  }
  .button {
    flex: 1;
    white-space: nowrap;
  }
}
