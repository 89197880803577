.container {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.title {
  font-weight: bold;
  font-size: 1.125rem;
  line-height: 1.375rem;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-right: 30px;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 28px;
  }

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    margin-bottom: 25px;
  }
}

.input {
  margin-bottom: 20px;

  &:last-of-type {
    margin-bottom: 45px;
  }
}

.dropdown {
  width: 100%;
  margin-bottom: 20px;
}

.textarea {
  textarea {
    @media screen and (min-width: 279px) and (max-width: 767px) {
      max-height: 80px;
    }

    @media screen and (min-width: 768px) {
      max-height: 95px;
    }
  }

  &:not(.textareaFullSize) {
    @media screen and (min-width: 768px) {
      width: calc(50% - 20px);
    }
  }

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-bottom: 30px;
  }

  &Fullsize {
    width: 100%;
  }
}

.form {
  @media screen and (min-width: 768px) {
    display: flex;
    flex-wrap: wrap;

    .input,
    .dropdown {
      width: calc(50% - 20px);
    }

    .input:nth-child(1),
    .input:nth-child(5),
    .dropdown {
      margin-right: 20px;
    }
  }
}
