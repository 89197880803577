@import "../../../styles/fonts.scss";
@import "../../../styles/_mixins.scss";

.container {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 12px;
  border-bottom: 1px solid var(--gray-text);
  border-right: 1px solid var(--gray-text);
  transition: var(--transition);

  &:before,
  &:after {
    position: absolute;
    top: -1px;
    left: -1px;
    content: "";
    background-color: var(--gray-dark);
    opacity: 0;
    transition: var(--transition);
  }

  &:before {
    width: calc(100% + 2px);
    height: 1px;
  }

  &:after {
    width: 1px;
    height: calc(100% + 2px);
  }

  &:hover {
    border-color: var(--gray-dark);

    &:before,
    &:after {
      opacity: 1;
    }
  }

  &.grid {
    min-width: 270px;
    flex: 1;
  }
}

.header {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 8px;
}

.type,
.year {
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 2px 8px;
  border-radius: 4px;
}

.type {
  background-color: #28335f0f;
  color: var(--gray-dark-text);
}

.year {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  background-color: rgb(from var(--primary) r g b / 13%);
  color: var(--primary);
}

.likeButton {
  align-items: center;
}

.details {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;

  &Inventory {
    margin-top: auto;
  }
}

.viewDetails {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding: 0 24px;
  font-weight: 500 !important;
  font-size: 0.875rem;
  line-height: 0.8125rem;
  color: var(--primary);
  transition: var(--transition);
  border: 1px solid var(--primary);
  cursor: pointer;

  &:hover {
    color: var(--white);
    background-color: var(--primary);
  }
}

.galery {
  position: relative;
  display: flex;
  height: 200px;
  cursor: pointer;

  & > img {
    object-fit: contain;
  }

  img {
    max-width: 100%;
  }

  &Logo {
    display: flex;
    flex-direction: column;
    justify-content: center;
    background-color: var(--gray-light);

    img {
      max-height: 40%;
      max-width: 75%;
      margin: 0 auto;
      object-fit: contain !important;
      filter: grayscale(100%);
    }
  }
}

.description {
  display: flex;
  justify-content: space-between;
  margin: 10px 0;

  &Container {
    display: flex;
    flex-direction: column;
    min-width: calc(50% - 10px);
    margin-right: 10px;
  }

  .name {
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
  }

  .make {
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.25rem;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  .name,
  .make {
    color: var(--black-dark);
  }

  .stock {
    margin-top: 20px;
    font-size: 0.75rem;
    line-height: 1rem;
    color: var(--gray-description);

    b {
      font-weight: 500;
    }
  }
}

.price {
  margin-top: 4px;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 1.25rem;
  color: var(--primary);

  &Small {
    font-size: 1rem;
    line-height: 1rem;
    flex-shrink: 0;
    margin-left: 10px;
  }
}

.noImageText {
  margin-top: 5px;
  font-size: 0.875rem;
  line-height: 1.5;
  text-align: center;
  color: var(--black);
  opacity: 0.3;
}
