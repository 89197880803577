.container {
  width: 100%;
  background-color: var(--white);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    height: 100vh;
    overflow-y: auto;
  }

  @media screen and (min-width: 768px) {
    height: 100%;
  }
}

.scheduleContainer {
  height: 100%;
}

.form {
  width: 100%;
  height: 100%;
  margin: 0 auto;

  @media screen and (min-width: 768px) {
    max-width: 480px;
  }

  &Container {
    @media screen and (min-width: 279px) and (max-width: 767px) {
      padding: 0 15px 40px;
    }

    @media screen and (min-width: 768px) {
      height: 100%;
      padding: 50px 25px;
    }
  }
}

.wellDone {
  width: 100%;
  height: 100%;
  //   height: 620px;
}

.steps {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    padding: 15px 15px 40px;
  }

  @media screen and (min-width: 768px) {
    padding: 50px 25px;
  }
}
