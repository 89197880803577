.container {
  display: flex;
  justify-content: space-between;
}

.dropdown {
  flex: 1;

  &:first-child {
    margin-right: 20px;
  }
}
