@import "../../../styles/fonts.scss";
@import "../../../styles/_mixins.scss";

.usedVehicles {
  display: flex;
  flex-direction: column;
  padding-top: 45px;
  background-color: var(--white);

  &Title {
    font-weight: bold;
    color: var(--black);

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      margin: 0 15px 40px;
      font-size: 1.5rem;
      line-height: 1.75rem;
    }

    @media screen and (min-width: 1280px) {
      margin: 0 105px 50px;
      font-size: 2rem;
      line-height: 2.1875rem;
    }
  }

  &Container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(210px, 6fr));
    margin-bottom: 40px;
    border-top: 1px solid var(--gray-border);
  }

  &Item {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100px;
    mix-blend-mode: luminosity;
    border-bottom: 1px solid var(--gray-border);
    border-right: 1px solid var(--gray-border);
    transition: var(--transition);
    background-color: transparent;

    img {
      max-width: 50%;
      max-height: 45%;
      object-fit: contain;
    }

    &:hover {
      mix-blend-mode: inherit;
      background-color: var(--gray-light);
    }
  }

  &Button {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 90px;
    width: 310px;
    height: 50px;
    font-weight: 500 !important;
    color: var(--white);
    background-color: var(--primary);
    transition: var(--transition);

    @media screen and (max-width: 1059px) {
      @include apply-font(preformatted, mobile, regular);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(preformatted, desktop, regular);
    }

    &:hover {
      background-color: var(--primary-dark);
    }
  }
}
