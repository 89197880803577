@import "../../../styles/fonts.scss";
@import "../../../styles/_mixins.scss";

.background {
  &.white {
    background-color: var(--white);
  }

  &.black {
    background-color: var(--black);
    & *:not(a):not(p) {
      color: var(--gray-soft);
    }

    & p {
      color: #d7d7e0;
    }
  }

  &.grey {
    // background-color: var(--primary-back-light);
    background-color: var(--gray-soft);
  }
}

.container {
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1059px) {
    padding: 32px 24px;
    gap: 24px;
  }

  @media screen and (min-width: 1060px) {
    padding: 64px 32px;
    margin: 0 auto;
    gap: 32px;
  }

  @media screen and (min-width: 1330px) {
    max-width: 1330px;
  }

  h1 {
    color: var(--black);

    @media screen and (max-width: 1059px) {
      @include apply-font(headings, mobile, h1);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(headings, desktop, h1);
    }
  }

  h2 {
    color: var(--black);

    @media screen and (max-width: 1059px) {
      @include apply-font(headings, mobile, h2);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(headings, desktop, h2);
    }
  }

  h3 {
    color: var(--black);
    @media screen and (max-width: 1059px) {
      @include apply-font(headings, mobile, h3);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(headings, desktop, h3);
    }
  }

  h4 {
    color: var(--black);
    @media screen and (max-width: 1059px) {
      @include apply-font(headings, mobile, h4);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(headings, desktop, h4);
    }
  }

  h5 {
    color: var(--black);
    @media screen and (max-width: 1059px) {
      @include apply-font(headings, mobile, h5);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(headings, desktop, h5);
    }
  }

  h6 {
    color: var(--black);
    @media screen and (max-width: 1059px) {
      @include apply-font(headings, mobile, h6);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(headings, desktop, h6);
    }
  }

  p {
    color: var(--black);
    @media screen and (max-width: 1059px) {
      @include apply-font(paragraph, mobile, regular);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(paragraph, desktop, regular);
    }
  }

  pre {
    color: var(--black);
    @media screen and (max-width: 1059px) {
      @include apply-font(preformatted, mobile, regular);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(preformatted, desktop, regular);
    }
  }

  ol {
    list-style: decimal;
    margin-left: 15px;
  }

  ul {
    list-style: disc;
    margin-left: 10px;
  }

  ul,
  ol {
    padding-left: 12px;
    @media screen and (max-width: 1059px) {
      @include apply-font(paragraph, mobile, regular);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(paragraph, desktop, regular);
    }

    li {
      padding-left: 12px;
    }
  }

  strong {
    font-weight: bold !important;
  }

  a {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    font-size: 1em;
    color: var(--primary);
    transition: var(--transition);

    &:hover {
      color: var(--primary-dark);
    }
  }

  img {
    width: 100%;
    object-fit: contain;
    height: 100%;
  }

  blockquote {
    font-style: italic;
    border-left: 2.5px solid var(--gray-border);

    @media screen and (min-width: 279px) and (max-width: 767px) {
      padding-left: 12px;
    }

    @media screen and (min-width: 768px) {
      padding-left: 20px;
    }
    @media screen and (max-width: 1059px) {
      @include apply-font(preformatted, mobile, regular);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(preformatted, desktop, regular);
    }
  }

  span {
    color: var(--gray-description);
    @media screen and (max-width: 1059px) {
      @include apply-font(paragraph, mobile, regular);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(paragraph, desktop, regular);
    }
  }

  button {
    width: fit-content;
    min-height: 40px;
    font-weight: 500 !important;
    padding: 0 24px;
    background-color: var(--primary);
    color: var(--white);
    outline: none;
    border: none;
    transition: var(--transition);

    &:hover {
      background-color: var(--primary-dark);
    }

    @media screen and (max-width: 1059px) {
      @include apply-font(preformatted, mobile, regular);
    }

    @media screen and (min-width: 1060px) {
      @include apply-font(preformatted, desktop, regular);
    }
  }

  table {
    width: 100%;

    @media screen and (min-width: 279px) and (max-width: 767px) {
      max-width: 100%;
      overflow-x: auto;
      display: block;
      margin: 0 0 50px;

      td {
        min-width: 165px;
        max-width: 185px;
      }
    }

    @media screen and (min-width: 768px) {
      background-color: var(--gray-light-menu);
      margin: 0 auto 50px;
    }

    @media screen and (min-width: 1050px) {
      max-width: 980px;
      margin: 0 auto 75px;
    }

    tr {
      @media screen and (max-width: 767px) {
        padding: 0 15px;
      }

      @media screen and (min-width: 768px) and (max-width: 1049px) {
        padding: 0 15px 0 20px;
      }

      @media screen and (min-width: 1050px) {
        padding: 0 50px 0 55px;
      }

      @media screen and (min-width: 768px) {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
      }

      &:first-child {
        border-bottom: 1px solid var(--gray-secondary);

        td {
          padding: 18px 0 20px;
          font-weight: 500;
          color: var(--gray-dark);
        }
      }

      &:not(:first-child) {
        td {
          @include apply-font(preformatted, mobile, regular);
          padding: 23px 10px 27px 0;
          color: var(--black);
          border-bottom: 1px solid var(--gray-border);
        }
      }
    }
  }
}
