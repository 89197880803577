.separator {
  position: relative;

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    content: "";
    width: 100%;
    border: none;
    border-top: 1px dotted #a5a5a5;
  }
}

.finalPrice {
  margin-bottom: 24px;
}