.container {
  display: flex;
  flex-direction: column;
  flex: 1;

  &WithPagination {
    position: relative;
  }
}

.paginationTop {
  border-bottom: 1px solid var(--gray-text);
}

.paginationBottom {
  box-shadow: 0px -1px 0px 0px var(--gray-text);
  border-bottom: 1px solid var(--gray-text);
}
