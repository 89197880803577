.container {
  border-top: 1px solid var(--gray-text);
  cursor: pointer;

  &:last-child {
    border-bottom: 1px solid var(--gray-text);
  }

  @media screen and (min-width: 279px) and (max-width: 767px) {
    padding: 40px 0;
  }

  @media screen and (min-width: 768px) {
    padding: 55px 0;
  }

  svg {
    path {
      transition: var(--transition);
    }
  }

  .minus {
    path {
      fill: var(--primary);
    }
    rect {
      fill: var(--white);
    }
  }

  .plus {
    path {
      stroke: var(--primary);
    }
  }

  &:hover {
    .minus {
      path {
        fill: var(--primary-dark);
      }
    }

    .plus {
      path {
        stroke: var(--primary-dark);
      }
    }
  }
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.question,
.answer {
  line-height: 1.5;
}

.question {
  display: flex;
  margin-right: 50px;
  font-weight: bold;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    font-size: 1.125rem;
  }

  @media screen and (min-width: 768px) {
    font-size: 1.25rem;
  }
}

.answer {
  color: var(--black);

  & * {
    line-height: 1.5;
  }

  @media screen and (min-width: 279px) and (max-width: 767px) {
    margin-top: 25px;
    font-size: 0.875rem;
  }

  @media screen and (min-width: 768px) {
    margin-top: 35px;
    font-size: 1.25rem;
  }

  p {
    & + ul,
    & + ol {
      margin-top: 20px;
    }
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  ul,
  ol {
    margin-bottom: 20px;
    padding-left: 12px;

    li {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  strong {
    font-weight: bold !important;
  }

  a {
    margin-left: 5px;
    color: var(--primary);
    transition: var(--transition);

    &:hover {
      color: var(--primary-dark);
    }
  }
}
