.title {
  margin: 32px auto 0;
  padding: 0 24px;
  width: 100%;
  max-width: 1330px;
  font-weight: bold;
  font-size: 2rem;
  color: var(--black);
  
  @media screen and (min-width: 1060px) {
    font-size: 2.5rem;
    padding: 0 32px;
  }
}
