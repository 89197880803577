.preliminaryTitle {
  font-weight: 700 !important;
  color: #181818 !important;
}

.tooltip {
  display: flex;
  align-items: center;
  margin-right: auto;
}

.propertyContainer {
  display: flex;
  align-items: center;
  margin-right: auto;
  gap: 3px;
}

.infoIcon {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: -16px;

  svg {
    width: 12px;
    height: 12px;
    flex-shrink: 0;
    color: #acaabb !important;
  }

  &.big {
    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.gray {
  color: var(--gray-dark-text) !important;
}

.title {
  padding-right: 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}