.infoValueContainer {
  display: flex;
  align-items: center;
  margin-left: 25px;

  p {
    // bug fix for text-overflow: ellipsis; not showing correctly.
    margin-left: -10px;
  }
}

.color {
  display: flex;
  flex-shrink: 0;
  margin-left: 10px;
  width: 11px;
  height: 11px;
  border: 1px solid #a4a4a4;

  &.circle {
    border-radius: 50%;
  }
}
