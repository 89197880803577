@import "../../../styles/fonts.scss";
@import "../../../styles/_mixins.scss";

.container {
  display: grid;
  background-color: var(--gray-light);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
    padding: 30px 0;
  }
}

.item {
  display: flex;
  flex: 1;
  flex-direction: column;
  max-width: 510px;

  @media screen and (min-width: 279px) and (max-width: 375px) {
    width: 100%;
  }

  @media screen and (min-width: 279px) and (max-width: 767px) {
    padding: 40px 15px;
  }

  @media screen and (min-width: 768px) and (max-width: 1279px) {
    padding: 45px 30px 50px;
  }

  @media screen and (min-width: 1280px) {
    padding: 40px 30px 45px;

    &Single {
      text-align: center;
      align-items: center;
    }
  }

  &Container {
    position: relative;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 279px) and (max-width: 375px) {
      max-width: 100%;
      overflow: hidden;
    }

    @media screen and (min-width: 279px) and (max-width: 767px) {
      &:not(:last-child) {
        &:after {
          position: absolute;
          bottom: 0;
          left: 15px;
          content: "";
          width: calc(100% - 30px);
          height: 1px;
          background-color: var(--white);
        }
      }
    }

    @media screen and (min-width: 768px) {
      &:not(:last-child) {
        border-right: 1px solid var(--white);
      }
    }
  }
}

.icon {
  flex-shrink: 0;

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    margin-bottom: 20px;
    width: 70px;
    height: 70px;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 35px;
    width: 80px;
    height: 80px;
  }
}

.title {
  margin-bottom: 15px;
  font-weight: bold;
  color: var(--black);

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    font-size: 1.25rem;
    line-height: 1.5rem;
  }

  @media screen and (min-width: 1280px) {
    font-size: 1.625rem;
    line-height: 1.875rem;
  }
}

.text {
  font-size: 1rem;
  line-height: 1.5rem;
  color: var(--dark);

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    margin-bottom: 30px;
  }

  @media screen and (min-width: 1280px) {
    margin-bottom: 35px;
  }

  @media screen and (min-width: 768px) and (max-width: 1919px) {
    max-width: 400px;
  }

  @media screen and (min-width: 1920px) {
    max-width: 510px;
  }

  a {
    color: var(--primary);
    margin-left: 5px;
    transition: var(--transition);

    &:hover {
      color: var(--primary-dark);
    }
  }
}

.link {
  background-color: transparent;
  @media screen and (min-width: 768px) {
    margin-top: auto;
  }

  @media screen and (min-width: 1280px) {
    width: 240px;
  }
}
