.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 36px;
  width: fit-content;
  padding: 0 20px;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 0.8125rem;
  border-radius: 8px;
  outline: none;
  transition: var(--transition);

  &.primary {
    border: none;
    background-color: var(--primary);
    color: var(--white);

    &:not(&.mobile):hover {
      background-color: var(--primary-dark);
    }
  }

  &.secondary {
    border: 1px solid var(--primary);
    background-color: var(--white);
    color: var(--primary);

    &:not(&.mobile):hover {
      background-color: var(--primary);
      color: var(--white);
    }
  }
}
