.container {
  position: relative;

  @media screen and (min-width: 768px) {
    padding: 0 15px;
  }

  @media screen and (min-width: 279px) and (max-width: 1023px) {
    margin: 35px 0;

    iframe {
      width: 100%;
    }
  }

  @media screen and (max-width: 767px) {
    iframe {
      height: 360px;
    }
  }

  @media screen and (min-width: 1024px) {
    margin: 50px auto;
  }
}
