.container {
  display: flex;
  align-items: center;
  height: 20px;
  font-weight: 500;
  font-size: 0.75rem;
  line-height: 1rem;
  padding: 2px 8px;
  border-radius: 4px;
  border: 1px solid var(--gray-dark-text);
  color: var(--gray-description);
  background-color: transparent;

  &.new {
    border: 1px solid var(--primary);
    color: var(--primary);
  }

  &.used {
    border-color: transparent;
    background-color: #28335f0f;
    color: var(--gray-dark-text);
  }
}
