.filter {
  position: sticky;
  top: 0;
  height: fit-content;
  max-width: 320px;
  min-width: 320px;

  &Button {
    position: sticky;
    bottom: 0;
    left: 0;
    flex-shrink: 0;
  }

  &Container {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 10px;
  }

  &Section {
    &:last-child {
      margin-bottom: 40px;
    }
  }
}
