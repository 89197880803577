.cars {
  display: flex;
  flex-wrap: wrap;
  overflow: inherit !important;

  &.grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(320px, 1fr));

    @media screen and (min-width: 279px) and (max-width: 1279px) {
      border-top: 1px solid var(--gray-text);
    }
  }
}