.container {
  img {
    pointer-events: none;
    object-fit: cover;
  }
}

.singleImage {
  max-width: 100%;
  width: 100%;

  & + .singleImage,
  & + .twoImages,
  & + .threeImages {
    margin-top: 10px;
  }
}

.twoImages {
  display: flex;
  flex-shrink: 0;
  width: 100%;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    flex-direction: column;
  }

  &Item {
    flex: 1;

    @media screen and (min-width: 279px) and (max-width: 767px) {
      width: 100%;
    }

    &:first-child {
      @media screen and (min-width: 768px) {
        max-width: calc(60% - 5px);
        margin-right: 10px;
      }

      @media screen and (min-width: 279px) and (max-width: 767px) {
        margin-bottom: 10px;
      }
    }

    &:last-child {
      @media screen and (min-width: 768px) {
        max-width: calc(40% - 5px);
      }
    }
  }

  & + .singleImage,
  & + .twoImages,
  & + .threeImages {
    margin-top: 10px;
  }
}

.threeImages {
  display: flex;

  @media screen and (min-width: 279px) and (max-width: 767px) {
    flex-direction: column;
  }

  @media screen and (min-width: 768px) {
  }

  &Item {
    flex: 1;

    &:last-of-type {
      @media screen and (min-width: 768px) {
        width: calc(70% - 10px);
      }
    }
  }

  &Container {
    display: flex;
    flex-direction: column;
    width: 100%;
    flex-shrink: 0;

    @media screen and (min-width: 768px) {
      max-width: 30%;
      margin-right: 10px;
    }

    @media screen and (min-width: 279px) and (max-width: 767px) {
      margin-bottom: 10px;
    }

    .threeImagesItem {
      width: 100%;

      &:first-child {
        margin-bottom: 10px;
      }
    }
  }

  & + .singleImage,
  & + .twoImages,
  & + .threeImages {
    margin-top: 10px;
  }
}
