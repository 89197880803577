.container {
  display: flex;
  flex-direction: column;
}

.inputs {
  display: flex;
  align-items: center;
}

.input {
  display: flex;
  width: 100%;
  height: 40px;
  padding: 0 10px 0 20px;
  font-size: 1rem;
  line-height: 1.5rem;
  border: 1px solid var(--gray-text);
  background-color: transparent;
  color: var(--black);
  outline: none;
  transition: var(--transition);

  @media screen and (min-width: 279px) and (max-width: 767px) {
    font-size: 16px;
  }

  &:focus,
  &:hover {
    border-color: var(--gray-secondary);
  }

  &.price {
    padding-left: 35px;
    background-image: url("/images/$.svg");
    background-repeat: no-repeat;
    background-size: auto;
    background-position: 20px;
  }
}

.delimiter {
  display: flex;
  flex-shrink: 0;
  width: 10px;
  height: 1px;
  margin: 0 5px;
  background-color: var(--gray-secondary);
}
