.container {
  display: flex;
  flex-direction: column;
  margin-bottom: -12px;

  @media screen and (min-width: 1280px) {
    padding: 0 16px;
  }
}

.item {
  font-size: 0.875rem;
  line-height: 1.0625rem;
  color: var(--gray-description);

  @media screen and (min-width: 768px) {
    padding-bottom: 10px;
  }

  &Dot {
    display: inline-flex;
    align-items: center;
    width: 3.68px;
    height: 17px;
    flex-shrink: 0;
    margin: 0 10px;

    &:before {
      content: "";
      display: inline-flex;
      flex-shrink: 0;
      width: 3.68px;
      height: 3.68px;
      background-color: var(--gray-description);
      border-radius: 50%;
    }
  }

  &Container {
    display: flex;
  }
}

.content {
  display: grid;

  @media screen and (min-width: 768px) {
    padding: 12px 0;
  }

  @media screen and (min-width: 279px) and (max-width: 1279px) {
    grid-template-columns: repeat(2, 1fr);
    gap: 0 24px;
  }

  @media screen and (min-width: 1280px) {
    grid-template-columns: repeat(4, 1fr);
    gap: 0px 32px;
  }
}

.tabs {
  margin-bottom: 25px;
}

.button {
  @media screen and (max-width: 767px) {
    width: calc(100% - 32px) !important;
    margin: 12px 16px 0;
  }

  @media screen and (min-width: 768px) {
    margin: 12px auto 0;
  }
}
